var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-row",
        {},
        [
          _vm.api.isLoading
            ? _c(
                "v-col",
                {
                  staticClass: "mx-auto pa-0",
                  attrs: { cols: "12", sm: "12" },
                },
                [
                  _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "table" },
                  }),
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticClass: "mx-auto pa-0",
                  attrs: {
                    align: "center",
                    justify: "center",
                    cols: "12",
                    sm: "12",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary ma-4" },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "white--text text-h4" },
                            [_vm._v(" Generate certificate ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "div",
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    dense: "",
                                                    outlined: "",
                                                    label: "Name",
                                                  },
                                                  model: {
                                                    value: _vm.attendance.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.attendance,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "attendance.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          label: "Duration ( minutes )",
                                          hint: _vm.convertToHours,
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.attendance.duration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.attendance,
                                              "duration",
                                              $$v
                                            )
                                          },
                                          expression: "attendance.duration",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-container", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-end" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit("cancel")
                                              },
                                            },
                                          },
                                          [_vm._v(" Cancel ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: { click: _vm.validateInput },
                                          },
                                          [_vm._v(" Confirm ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }